import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

import abh from "../images/abh.png"
import aeroport from "../images/aeroport.jpg"
import alisa from "../images/alisa.png"
import bilyar from "../images/bilyar.png"
import bristol from "../images/bristol.png"
import dosaaf from "../images/dosaaf.jpg"
import frant from "../images/frant.png"
import icl from "../images/icl.jpg"
import kolco from "../images/kolco.jpg"
import nefis from "../images/nefis.png"
import port from "../images/port.png"
import rusalko from "../images/rusalko.jpg"
import savinovo from "../images/savinovo.jpg"
import shoko from "../images/shoko.jpg"
import tandem from "../images/tandem.jpg"
import tatspirt from "../images/tatspirt.png"
import tatuasdb from "../images/tatuasdb.png"
import tatxim from "../images/tatxim.png"
import timer from "../images/timer.jpg"

const logos = [
  abh,
  aeroport,
  alisa,
  bilyar,
  bristol,
  dosaaf,
  frant,
  icl,
  kolco,
  nefis,
  port,
  rusalko,
  savinovo,
  shoko,
  tandem,
  tatspirt,
  tatuasdb,
  tatxim,
  timer,
]

const Orgs = () => (
  <Layout>
    <Seo title="Партнеры" />
    <div className="py-10 font-raleway px-4 md:px-0">
      <h1 className="text-3xl font-semibold">Партнеры</h1>
      <div className="py-8">
        <div className="grid grid-cols-6">
          {logos.map(it => {
            return (
              <div
                key={it}
                className="flex w-full items-center justify-center hover:bg-slate-100"
              >
                <img
                  src={it}
                  className="w-40"
                  alt="Партнеры КФП – экология"
                  title="Партнеры КФП – экология"
                />
              </div>
            )
          })}
        </div>
      </div>
    </div>
  </Layout>
)

export default Orgs
